<template>
    <v-container
    id="login"
    fluid
    tag="section"
  >
  
    <v-toolbar color="#1B5E20">
      <v-btn
        icon
        dark
        @click.native="close"
        color="white"
        elevation="0"
        class="white--text"
        large
      >
        <v-icon>mdi-arrow-left-circle</v-icon>
      </v-btn>
      <v-toolbar-title class="white--text">Login and Start Shopping!</v-toolbar-title>
    </v-toolbar>
    <v-divider></v-divider>
    <v-container>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="10">
          <v-card class="elevation-6 mt-10">
            <v-window v-model="step">
              <v-window-item :value="1">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-card-text class="mt-12">
                      <h4 class="text-center">Login in to Your Account</h4>
                      <v-row align="center" justify="center">
                        <v-col cols="12" sm="8">
                          <v-card-text>
                            <v-form ref="form" v-model="valid" lazy-validation>
                              <v-text-field
                                v-model="email"
                                :rules="emailRules"
                                label="E-mail"
                                required
                                append-icon="mdi-email"
                              >
                              </v-text-field>
                              <v-text-field
                                v-model="password"
                                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                :rules="passwordRules"
                                :type="showPassword ? 'text' : 'password'"
                                label="Password"
                                hint="At least 6 characters"
                                counter
                                @click:append="showPassword = !showPassword"
                              ></v-text-field>
                              <v-row>
                                <v-col cols="12" sm="7">
                                  <v-checkbox
                                    label="Remember Me"
                                    class="mt-n1"
                                    color="#1B5E20"
                                  >
                                  </v-checkbox>
                                </v-col>
                                <v-col cols="12" sm="5">
                                  <!-- <a
                                    class="black--text"
                                    @click="setDialogComponent('forgot')"
                                    >Forgot Password</a
                                  > -->
                                </v-col>
                              </v-row>
                              <br />
                              <div class="text-xs-center">
                                <v-btn
                                  color="#1B5E20"
                                  :disabled="!valid"
                                  @click="submit"
                                  onloadstart="loading"
                                  class="white--text"
                                >
                                  Login
                                  <v-icon right color="white">mdi-lock-open</v-icon>
                                </v-btn>
                              </div>
                            </v-form>
                          </v-card-text>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    class="rounded-bl-xl hidden-sm-and-down"
                    style="background-color: #1b5e20"
                  >
                    <div style="text-align: center; padding: 180px 0">
                      <v-card-text class="white--text">
                        <h3 class="text-center">Don't Have an Account Yet?</h3>
                        <h6 class="text-center">
                          Let's get you all set up so you can start creating your your
                          first<br />
                          onboarding experience
                        </h6>
                      </v-card-text>
                      <div class="text-center">
                        <v-btn tile outlined dark @click="step++">SIGN UP</v-btn>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-window-item>
              <v-window-item :value="2">
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                    class="rounded-br-xl"
                    style="background-color: #1b5e20"
                  >
                    <div style="text-align: center; padding: 180px 0">
                      <v-card-text class="white--text">
                        <h3 class="text-center">Alredy Signed up?</h3>
                        <h6 class="text-center">
                          Log in to your account so you can continue building and<br />
                          editing your onboarding flows
                        </h6>
                      </v-card-text>
                      <div class="text-center">
                        <v-btn tile outlined dark @click="step--">Log in</v-btn>
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-card-text class="mt-12">
                      <h4 class="text-center">Sign Up for an Account</h4>
                      <h6 class="text-center black--text">
                        Let's get you all set up so you can start creatin your <br />
                        first onboarding experiance
                      </h6>
                      <v-row align="center" justify="center">
                        <v-col cols="12" sm="8">
                          <v-form ref="form" v-model="valid" lazy-validation>
                            <v-text-field
                              v-model="name"
                              :rules="nameRules"
                              :counter="50"
                              label="Name"
                              required
                              append-icon="mdi-user"
                            ></v-text-field>
                            <v-text-field
                              v-model="email"
                              :rules="emailRules"
                              label="E-mail"
                              required
                              append-icon="mdi-email"
                            >
                            </v-text-field>
                            <v-text-field
                              v-model="password"
                              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                              :rules="passwordRules"
                              :type="showPassword ? 'text' : 'password'"
                              label="Password"
                              hint="At least 6 characters"
                              counter
                              @click:append="showPassword = !showPassword"
                            >
                            </v-text-field>
                            <v-checkbox
                              v-model="checkbox"
                              :rules="[(v) => !!v || 'You must agree to continue!']"
                              label="Do You agree with our Privacy Policy?"
                              required
                              color="#1B5E20"
                            ></v-checkbox>
                            <div class="text-xs-center">
                              <v-btn
                                color="#1B5E20"
                                :disabled="!valid"
                                @click="submitRegister"
                                class="white--text mr-4"
                              >
                                Register
                                <v-icon right dark>mdi-account-plus</v-icon>
                              </v-btn>

                              <v-btn
                                @click="clear"
                                color="#1B5E20"
                                class="white--text mr-4"
                              >
                                Reset
                                <v-icon right dark>mdi-lock-reset</v-icon>
                              </v-btn>
                            </div>
                          </v-form>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-window-item>
            </v-window>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <template>
      <v-layout row justify-center>
        <v-dialog v-model="progressBar" persistent max-width="290" id="progressBar">
          <v-progress-linear indeterminate color="blue" height="30"
            ><span>Waits...</span></v-progress-linear
          >
        </v-dialog>
      </v-layout>
    </template>
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "login",

  data() {
    return {
      valid: true,
      email: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) =>
          /([a-zA-Z0-9_]{1,})(@)([a-zA-Z0-9_]{2,}).([a-zA-Z0-9_]{2,})+/.test(v) ||
          "E-mail must be valid",
      ],
      showPassword: false,
      password: "",
      passwordRules: [
        (v) => !!v || "Password required.",
        (v) => (v && v.length >= 6) || "Min 6 characters",
      ],
      progressBar: false,
      step: 1,
    };
  },
  props: {
    source: String,
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      prevUrl: "prevUrl",
      currentComponent: "dialog/component",
    }),
  },
  methods: {
    ...mapActions({
      setAlert: "alert/set",
      setAuth: "auth/set",
      setDialogComponent: "dialog/setComponent",
    }),
    submit() {
      if (this.$refs.form.validate()) {
        this.progressBar = true;
        let formData = {
          email: this.email,
          password: this.password,
        };

        this.axios
          .post("/login", formData)
          .then((response) => {
            let { data } = response.data;
            this.setAuth(data);
            this.progressBar = false;
            if (this.user.id > 0) {
              this.setAlert({
                status: true,
                color: "success",
                text: "Login success",
              });
              if (this.prevUrl.length > 0) this.$router.push(this.prevUrl);
              this.close();
            } else {
              this.setAlert({
                status: true,
                color: "error",
                text: "Login failed",
              });
            }
          })
          .catch((error) => {
            this.progressBar = false;
            let { data } = error.response;
            this.setAlert({
              status: true,
              color: "error",
              text: data.message,
            });
          });
      }
    },
    submitRegister() {
      if (this.$refs.form.validate()) {
        this.progressBar = true;
        let formData = new FormData();
        formData.set("name", this.name);
        formData.set("email", this.email);
        formData.set("password", this.password);
        this.axios
          .post("/register", formData)
          .then((response) => {
            let { data } = response.data;
            this.setAuth(data);
            this.setAlert({
              status: true,
              color: "success",
              text: "Register success",
            });
            this.close();
          })
          .catch((error) => {
            let { data } = error.response;
            this.setAlert({
              status: true,
              color: "error",
              text: data.message,
            });
          });

        this.progressBar = false;
      }
    },
    close() {
      return this.$router.push({ path: "/" });
    },
  },
};
</script>
<style scoped>
.v-progress-circular {
  margin: 1rem;
}
</style>
